<template>
    <div class="row">
        <OFormControl title="Review Responsible" class="col-12 col-xl-3">
            <ODataLookup :data-object="dsLocal_dsPersonsWithAccess"
                openonfocus
                :bind="sel=> { props.dataObject.current.ReviewResponsible_ID=sel.ID; props.dataObject.current.ReviewResponsible=sel.Name;}"
                class="form-control form-control-sm"
                :height="400"
                :value="props.dataObject.current.ReviewResponsible">
                <OColumn name="ID" width="80"/> 
                <OColumn name="Name" width="300"/>
            </ODataLookup>
        </OFormControl>
        <OFormControl title="IDC Responsible" class="col-12 col-xl-3" v-if="IDCEnabled">
            <ODataLookup :data-object="dsLocal_dsPersonsWithAccess"
                openonfocus
                :bind="sel=> { props.dataObject.current.IDCResponsible_ID=sel.ID; props.dataObject.current.IDCResponsible=sel.Name;}"
                class="form-control form-control-sm"
                :height="400"
                :value="props.dataObject.current.IDCResponsible">
                <OColumn name="ID" width="80"/> 
                <OColumn name="Name" width="300"/>
            </ODataLookup>
        </OFormControl>
    </div>
 

    <DistSetupPersons :dataObject="props.dataObject" ref="DistSetupPersonsRef" class="mt-3"></DistSetupPersons>
    <DistSetupOrgUnits :dataObject="props.dataObject" ref="DistSetupOrgUnitsRef" class="mt-3"></DistSetupOrgUnits>
    <DistSetupGroups :dataObject="props.dataObject" ref="DistSetupGroupsRef" @insertDelete="refreshGrids" class="mt-3 mb-1"></DistSetupGroups>
    <PublishedToOrgUnits :dataObject="props.dataObject"/>
</template>

<script setup>
    import { ref,watch } from 'vue';
    import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts'
    import DistSetupPersons from 'ECM.vue.components.DistributionSetupPersons.vue'
    import DistSetupOrgUnits from 'ECM.vue.components.DistributionSetupOrgUnits.vue'
    import DistSetupGroups from 'ECM.vue.components.DistributionSetupGroups.vue'
    import PublishedToOrgUnits from 'ECM.vue.components.DistributionPublishToOrgUnits.vue'

    const props = defineProps({
        dataObject: Object
    })

    const DistSetupPersonsRef = ref(null),  DistSetupOrgUnitsRef = ref(null),  DistSetupGroupsRef = ref(null);
    const IDCEnabled = ref(false)

    const dsSettingsDef = {
        id: 'dsLocal_Settings',
        viewName: 'atbv_Arena_Settings',
        appId: props.dataObject.appId,
        maxRecords: 100,
        loadRecents: true,
        distinctRows: true,
        disableAutoLoad: true,
        fields:
            [
                {name: "IDCProcessEnabled", type: "bool"}
            ]
    }

    const personsWithAccessDatasourceDefinition = {
        id: 'dsLocal_PersonsWithAccess',
        viewName: 'aviw_ECM_PersonsWithAccess',
        appId: props.dataObject.appId,
        maxRecords: 100,
        loadRecents: true,
        distinctRows: true,
        disableAutoLoad: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Name", type: "string"},
                {name: "Document_ID", type: "string"}
            ]
    }

    const dsLocal_Settings = getOrCreateDataObject(dsSettingsDef);
    const dsLocal_dsPersonsWithAccess = getOrCreateDataObject(personsWithAccessDatasourceDefinition);

    dsLocal_Settings.load().then(() => {
        if(dsLocal_Settings.data.length > 0)
            IDCEnabled.value = dsLocal_Settings.data[0].IDCProcessEnabled
    })

    function refreshGrids(){
        DistSetupPersonsRef.value.refreshGrid();
        DistSetupOrgUnitsRef.value.refreshGrid();
    }
</script>